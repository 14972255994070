import React from "react"
import { CaretRightOutlined } from "@ant-design/icons"
import { Button, Col, Collapse, Popover, Row, Typography } from "antd"
import { graphql } from "gatsby"
import Shell from "../components/Shell/Shell"
import SEO from "@avldev/gatsby-theme-core-ui/src/components/SEO"
import Hero from "../components/SharpHero/SharpHero"
import BookingBar from "../components/BookingBar/BookingBar"
import GoogleMap from "../components/GoogleMap/GoogleMap"
import SeoHeader from "../components/SeoHeader/SeoHeader"

const description = `The Residences at Biltmore all suite hotel is in close proximity to a wide variety of things to do all around Asheville. With prime location to downtown Asheville guests with love our location.`

const rowProps = {
  align: "middle",
  gutter: 32,
  justify: "center",
  type: "flex",
}

const fullColProps = {
  xs: 22,
  md: 20,
  lg: 18,
  xl: 16,
}

const directions = [
  {
    from: "From the Asheville Airport",
    steps: `
      Head south on Terminal Dr (0.4 mi.)
      Keep right to continue toward Airport Rd
      Turn left onto Airport Rd (0.7 mi.)
      Turn left to merge onto I-26 W/US-74 W toward Asheville (0.2 mi.)
      Merge onto I-26 W/US-74 W (8.6 mi.)
      Take exit 31A for Interstate 40 E toward Hickory (0.6 mi.)
      Merge onto I-40 E (3.9 mi.)
      Take exit 50 for US-25 toward S Asheville (0.3 mi.)
      Keep left to continue toward US-25 N/Hendersonville Rd
      Turn left onto US-25 N/Hendersonville Rd and get in the far right lane (0.6 mi.)
      Continue straight onto Hendersonville Rd onto Biltmore Ave (0.3 mi.)
      Destination will be on the right after going through the stop light past the train tracks (0.2 mi.)
    `,
  },
  {
    from: "From the South (Atlanta Area)",
    steps: `
      Take I-85 N (28.4 mi.)
      Veer left Onto I-985 N/ Lanier Parkway [Follow signs for Gainesville] (28.4 mi.)
      Continue on Cornelia Hwy / US-23 N (64 mi.)
      Take the ramp onto US-23 N / US-441 N
      Continue to follow US-23 N / US-441 N (16.5 mi.)
      Merge onto US-74 E / Great Smokey Mountains Expy (26.0 mi.)
      Merge onto I-40 E (18.1 mi.)
      Take Exit 50 / S Asheville (0.3 mi.)
      Turn left onto Hendersonville Rd (0.9 mi.)
      Continue onto Biltmore Ave (Residences at Biltmore will be on you right)
    `,
  },
  {
    from: "From Florida",
    steps: `
      Take I-95 N [Passing though Georgia/Entering into South Carolina] (320.0 mi.)
      Take exit 86B to merge onto I-26 W toward Columbia [Entering North Carolina] (208 mi.)
      Keep right to stay on I-26 W, follow sings for Interstate 26 W/Asheville/Interstate 240 (1.8 mi.)
      Take Exit 1C for Amboy Rd (0.2 mi.)
      Continue Straight (1.5 mi.)
      Turn right onto Meadow Rd (1.5 mi.)
      Turn left onto Biltmore Ave (Residences at Biltmore will be on you right)
    `,
  },
  {
    from: "From the North (Washington DC area)",
    steps: `
      Take I-95 S (118.0 mi.)
      Take exit 51, Merge onto I-85 S /US-460 W toward Durham (140.0 mi.)
      Slight aright at I-40 W (206.6 mi.)
      Take Exit 50B / S Asheville (1.1 mi)
      Turn right onto Hendersonville Rd (0 .9 mi.)
      Continue onto Biltmore Ave (Residences at Biltmore will be on your right)
    `,
  },
  {
    from: "From the East (Raleigh area)",
    steps: `
      Take I-40 W [follow sings for interstate 40 W/Winston-Salem] (237.0 mi.)
      Take Exit 50B / S Asheville (0.3 mi.)
      Turn right onto Hendersonville Rd (0 .9 mi.)
      Continue onto Biltmore Ave (Residences at Biltmore will be on your right)
    `,
  },
]

const popoverStyle = { borderBottom: `2px dotted #4296b4`, cursor: `pointer` }

const PhonePopover = ({ num, text }) => (
  <Popover
    content={
      <Button size="large" type="primary">
        <a href={`tel:${num}`} style={{ fontWeight: 600 }}>
          Click to Call
        </a>
      </Button>
    }
    placement="bottom"
  >
    <span style={popoverStyle}>{text}</span>
  </Popover>
)

export default ({ data, location }) => {
  const { hero } = data

  return (
    <Shell>
      <SEO
        meta={{
          description,
          image: hero.fluid.src,
          imageAlt: hero.description,
        }}
        title="Location & Directions Hotels Near Biltmore - The Residences at Biltmore"
        url={location.href}
      />
      <Hero
        caption="Location & Directions"
        contentfulAsset={data.hero}
        height={`40vh`}
        imageStyle={{
          filter: `brightness(0.65)`,
          objectPlacement: `top`,
        }}
      />
      <BookingBar showCTAButtons={false} />
      <Row
        {...rowProps}
        style={{ margin: `auto`, marginTop: 24, maxWidth: 1360 }}
      >
        <Col {...fullColProps}>
          <SeoHeader
            seo={{ level: 1, content: "Location" }}
            human={{ level: 4, content: "Welcome to Biltmore Village" }}
          />
          <Typography.Text>
            The Residences at Biltmore all suite hotel is in close proximity to
            a wide variety of things to do. Below guests will find the
            directions to our beautiful property. Please feel free to{" "}
            <PhonePopover num={data.map.phone} text="contact us" /> for more
            information.
          </Typography.Text>
        </Col>
      </Row>
      <Row
        {...rowProps}
        style={{ margin: `auto`, marginTop: 24, maxWidth: 1360 }}
      >
        <Col {...fullColProps}>
          <Typography.Paragraph
            strong
            style={{
              alignItems: `center`,
              background: `#FAFAFA`,
              border: `2px solid #D9D9D9`,
              borderRadius: 4,
              display: `flex`,
              justifyContent: `center`,
              height: 130,
              margin: `auto`,
              minWidth: 300,
              textAlign: `center`,
              width: `50%`,
            }}
          >
            {data.map.phone}
            <br />
            {data.map.streetAddress}
            <br />
            {data.map.cityStateZip}
          </Typography.Paragraph>
        </Col>
      </Row>
      <Row
        {...rowProps}
        style={{ margin: `auto`, marginTop: 24, maxWidth: 1360 }}
      >
        <Col {...fullColProps}>
          <Typography.Title level={4}>
            Residences at Biltmore’s Driving Directions:
          </Typography.Title>
          <Collapse
            accordion
            bordered={false}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            {directions.map((dir, index) => (
              <Collapse.Panel
                header={<Typography.Text strong>{dir.from}</Typography.Text>}
                key={index}
              >
                <div className="print">
                  <ol>
                    {dir.steps
                      .split(`\n`)
                      .map(line => line.trim())
                      .filter(line => line.length)
                      .map((line, index) => (
                        <li key={index}>{line}</li>
                      ))}
                  </ol>
                </div>
              </Collapse.Panel>
            ))}
          </Collapse>
        </Col>
      </Row>
      <Row {...rowProps} style={{ marginTop: 48 }}>
        <Col span={24} style={{ marginBottom: -32 }}>
          <GoogleMap
            center={{
              lat: data.map.location.lat,
              lng: data.map.location.lon,
            }}
            disableDefaultUI
            markers={[
              {
                address: {
                  street: data.map.streetAddress,
                  locality: data.map.cityStateZip,
                },
                label: "Residences at Biltmore",
                lat: data.map.location.lat,
                lng: data.map.location.lon,
              },
            ]}
            noMapType
            noScroll
          />
        </Col>
      </Row>
    </Shell>
  )
}

export const query = graphql`
  {
    hero: contentfulAsset(title: { eq: "ExteriorPinkSkyFeatured" }) {
      ...HeroFluid
    }
    map: contentfulHotel(name: { eq: "Residences at Biltmore" }) {
      ...HotelContact
    }
  }
`
